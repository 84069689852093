div:has(> .ps-datasets-table) {
    height: 260px !important;
}

.text-lowercase {
    text-transform: lowercase;
}

.chart-container {
    width: auto;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.chart-container .bulk-delete {
    gap: 15px;
    margin: 0;
    left: 100px;
    bottom: 5px;
    display: flex;
    color: #9a9a9a;
    position: absolute;
    align-items: center;
}

.chart-container .bulk-delete button {
    margin: 0;
}

.chart-container canvas {
    width: 90% !important;
    height: auto !important;
    background: white !important;
}

.dataset-popup-action {
    display: flex;
    width: 100%;
}

.green-circle {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    background: #34A853;
    color: black;
    border-radius: 50%;
}

.checklist-title {
    color: #5b83ca;
    margin: 0 10px;
}

.checklists-container {
    display: grid;
    grid-template-columns: 50% 50%;
}

.checklists-container .checklist-item {
    font-size: 0;
    padding: 10px;
}

.checklists-container .checklist-item img {
    width: 100%;
}

.checklists-container .checklist-item label {
    display: flex;
    margin: 5px 0;
    font-size: 15px;
    cursor: pointer;
    align-items: flex-start;
}

.checklists-container .checklist-item label span {
    padding: 0;
}

.error {
    color: #be152c;
}

.excel-example-formats {
    display: flex;
    flex-direction: column;
}

.excel-example-formats p {
    margin: 0;
    height: 30px;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    align-items: center;
    line-height: 1.4375em;
    color: rgba(203, 204, 210, 0.7);
    font-family: "Inter", sans-serif;
}

.report-issue-uploader .dzu-dropzone {
    overflow: auto;
    min-height: 160px;
    border: 1px solid #222641;
}

.report-issue-uploader .dzu-dropzone .preview-container {
    width: 100%;
    border-bottom: 1px solid #222641;
}

.report-issue-uploader .dzu-dropzone .preview-container .preview-content {
    width: 100%;
    font-size: 0;
    height: 150px;
    display: grid;
    padding: 15px;
    grid-gap: 15px;
    grid-template-columns: 180px calc(100% - 234px) 24px;
}

.report-issue-uploader .dzu-dropzone .preview-container .preview-content .image-area {
    width: 100%;
    height: 120px;
}

.report-issue-uploader .dzu-dropzone .preview-container .preview-content .image-area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.report-issue-uploader .dzu-dropzone .preview-container .preview-content .input-area textarea {
    width: 100%;
    resize: none;
    transition: none;
    height: 120px!important;
}

.report-issue-uploader .dzu-dropzone .preview-container .preview-content .action-area {
    display: flex;
    align-items: center;
    justify-content: center;
}

.report-issue-uploader .dzu-dropzone .preview-container .preview-content .action-area svg {
    cursor: pointer;
}

.report-issue-uploader .dzu-dropzone .preview-container .preview-error {
    color: #be152c;
    font-weight: bold;
    padding: 0 15px 15px 15px;
}

.report-issue-uploader .dzu-dropzone .dzu-inputLabel {
    font-size: 14px;
    border-radius: 4px;
    background-color: #484c64;
}

.report-issue-uploader .dzu-dropzone .dzu-inputLabel .clickable-area {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.report-issue-uploader .dzu-dropzone .dzu-inputLabel .clickable-area span {
    color: #bbbcc5;
}

.report-issue-uploader .dzu-dropzone .dzu-inputLabel .clickable-area svg {
    color: #7c7f90;
}

.report-issue-uploader .dzu-dropzone .dzu-inputLabel:hover .clickable-area svg {
    color: #bbbcc5;
}

.report-issue-uploader .dzu-dropzone .dzu-inputLabelWithFiles {
    height: 40px;
    margin: 20px;
    transition: .5s;
    font-weight: bold;
    padding: 11px 24px;
    border-radius: 10px;
    background: transparent;
    color: rgb(140, 124, 240);
    border: 1px solid rgba(140, 124, 240, 0.5);
}

.report-issue-uploader .dzu-dropzone .dzu-inputLabelWithFiles:hover {
    border: 1px solid rgb(140, 124, 240);
    background-color: rgba(140, 124, 240, 0.1);
}

.machine-issue-files {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
}

.text-ellipsis-80 {
    max-height: 80px;
    overflow: hidden;
}

.machine-issue-comment-container .chat-container {
    gap: 10px;
    display: flex;
    padding: 10px;
    border-radius: 10px;
    flex-direction: column;
    border: 1px solid #3e425a;
}

.machine-issue-comment-container .chat-container .chat-item {
    display: flex;
    flex-direction: column;
}

.machine-issue-comment-container .chat-container .chat-item .comment-container {
    gap: 5px;
    display: flex;
    max-width: 80%;
    padding: 5px 10px;
    border-radius: 5px;
    flex-direction: column;
    border: 1px solid #8c7cf0;
}

.machine-issue-comment-container .chat-container .chat-item .comment-container .due-date-area {
    font-size: 10px;
}

.machine-issue-comment-container .chat-container .chat-item .comment-container .document-area {
    display: flex;
    color: white;
    cursor: pointer;
    font-size: 10px;
    align-items: center;
}

.machine-issue-comment-container .chat-container .chat-item .timestamp-container {
    color: #747da3;
    font-size: 10px;
    margin-top: 5px;
}

.machine-issue-comment-container .chat-container .chat-item.me {
    align-items: end;
}

.machine-issue-comment-container .chat-container .chat-item.companion {
    align-items: start;
}

.machine-issue-comment-container .chat-container .chat-item.me .comment-container {
    color: white;
    background: #8c7cf0;
}

.machine-issue-comment-container .form-container {
    font-size: 0;
}

.machine-issue-comment-container .form-container .additional-fields {
    gap: 10px;
    display: grid;
    margin-bottom: 10px;
    grid-template-columns: repeat(2, 1fr);
}

.machine-issue-comment-container .form-container .additional-fields .due-date > label {
    margin-left: 3px;
    margin-bottom: -5px;
}

.machine-issue-comment-container .form-container .additional-fields .due-date > div {
    margin: 0;
}

.machine-issue-comment-container .form-container .additional-fields .due-date input {
    border: none !important;
}

.machine-issue-comment-container .form-container .additional-fields .due-date input:focus {
    padding: 10px 12px !important;
}

.machine-issue-comment-container .form-container .additional-fields .due-date fieldset {
    border-color: #40435b;
}

.machine-issue-comment-container .form-container .additional-fields .document label {
    margin-left: 3px;
    margin-bottom: -5px;
}

.machine-issue-comment-container .form-container .additional-fields .document .document-inner {
    gap: 10px;
    display: flex;
    align-items: center;
}

.machine-issue-comment-container .form-container .additional-fields .document .document-inner input {
    display: none;
}

.machine-issue-comment-container .form-container .additional-fields .document .document-inner .uploaded-container {
    gap: 5px;
    height: 43px;
    padding: 5px;
    display: flex;
    overflow: hidden;
    border-radius: 8px;
    align-items: center;
    border: 1px solid #40435b;
}

.machine-issue-comment-container .form-container .additional-fields .document .document-inner .uploaded-container p {
    margin: 0;
    font-size: 13px;
    color: #cbccd2b3;
}

.machine-issue-comment-container .form-container .additional-fields .document .document-inner .uploaded-container svg {
    color: #8C7CF0;
    cursor: pointer;
}

.machine-issue-comment-container .form-container .message-field {
    position: relative;
}

.machine-issue-comment-container .form-container .message-field textarea {
    width: 100%;
    resize: none;
    height: 120px !important;
    overflow: hidden auto !important;
}

.machine-issue-comment-container .form-action {
    display: flex;
    justify-content: space-between;
}

.sensors-autocomplete > div > div {
    padding: 6px!important;
}

.bookable-machines-calendar {
    display: flex;
    padding: 15px!important;
    align-items: center;
    gap: 15px;
}

.bookable-machines-calendar > p {
    margin: 0;
}

.bookable-machines-calendar .calendar-dates {
    display: flex;
    gap: 10px
}

.bookable-machines-calendar .calendar-dates .calendar-date {
    display: flex;
    align-items: center;
    gap: 10px;
}

.bookable-machines-calendar .calendar-dates .calendar-date > p {
    margin: 0;
}

.bookable-machines-calendar .calendar-dates .calendar-date > label {
    margin-left: 3px;
    margin-bottom: -5px;
}

.bookable-machines-calendar .calendar-dates .calendar-date > div {
    margin: 0;
    height: 43px;
}

.bookable-machines-calendar .calendar-dates .calendar-date input {
    border: none !important;
}

.bookable-machines-calendar .calendar-dates .calendar-date input:focus {
    padding: 10px 12px !important;
}

.bookable-machines-calendar .calendar-dates .calendar-date fieldset {
    border-color: #40435b;
}